<template>
  <!-- bg-[#FAFAFA] rounded-[10px] shadow w-screen mx4 sm:mx6 lg:mx8 absolute inset-x-10 top-[88px] h-full   relative -->
  <div class="bg-[#FAFAFA] py-8 px-[25px] sm:px-6 lg:px-8 absolute inset-x-0 top-[124px] mx-auto rounded-t-xl lg:max-w-[84rem] h-[130px] z-20">
    <div class="mx-auto max-w-7xl">
      <div v-if="hasSlot('body')">
        <slot name="body"></slot>
      </div>
      <div v-else>
        <p class="font-normal text-2xl text-black font-cooper">{{ currentRouteName }}</p>
        <p class="font-normal text-base text-black">{{ currentRouteSubText }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, useSlots } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const slots = useSlots();

const hasSlot = (name) => {
  return !!slots[name];
};

const currentRouteName = computed(() => {
  return route.meta.title || route.name;
});

const currentRouteSubText = computed(() => {
  return route.meta.text;
});
</script>